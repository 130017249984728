<template>
  <div class="wrapper-landing">
    <LandingHero
      title="Créez votre liste de naissance en ligne et gratuite"
      subtitle="Ajoutez les cadeaux de naissance que vous préférez et partagez-les dans une belle liste."
      origin="listabebe"
      lang="fr-FR"
      buttonText="Créez votre liste gratuitement"
    >
      <template v-slot:cover>
        <source
          media="(max-width: 991px)"
          class=""
          srcset="../../assets/img/landings/cover-mujer-joven-embarazada-movil-resp.jpg"
          alt=""
          loading="lazy"
        />
        <img
          class="ln-hero__inset"
          src="../../assets/img/landings/cover-mujer-joven-embarazada-movil.jpg"
          alt=""
          loading="lazy"
        />
      </template>
    </LandingHero>
    <main class="bg-cercles">
      <LandingAppRatings />

      <section class="bb-container">
        <LandingFeaturesSimple title="Notre liste de naissance vous aidera si :"
        :use-carousel="false"
        >
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-embarazada.svg" alt="" />
            </template>
            <template slot="title">
              Vous êtes un jeune parent et vous ne savez pas ce qu’il faut acheter.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-q.svg" alt="" />
            </template>
            <template slot="title">
              Votre famille et vos amis vous demandent sans cesse ce que vous voulez pour le bébé.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-mobile-cart.svg" alt="" />
            </template>
            <template slot="title"> Vous voulez choisir des produits provenant de plusieurs magasins. </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-hand-heart.svg" alt="" />
            </template>
            <template slot="title">
              Vous cherchez un moyen simple et agréable de partager vos envies.
            </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-clipboard.svg" alt="" />
            </template>
            <template slot="title">
              Vous aimez vous organiser et ne voulez rien oublier.
            </template>
          </LandingFeaturesSimpleItem>
        </LandingFeaturesSimple>
      </section>
    </main>

    <LandingFeaturesCover title="Une liste de bébé remplie de bonnes choses">
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/landings/cover-retailers-box-fr.png" alt="" />
        </template>
        <template slot="titulo">
          Demandez n’importe quel modèle, de n’importe quelle marque, de n’importe quelle boutique.
        </template>

        <template slot="descripcion">
          <p>
            Copiez un lien et ajoutez-le à votre liste. Liberté totale pour choisir exactement vos cadeaux de naissance
          </p>
        </template>
      </LandingFeaturesCoverItem>
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/cover-regalos-efectivo.svg" alt="" />
        </template>
        <template slot="titulo"> Collectez de l'argent sans commissions </template>

        <template slot="descripcion">
          <p>
            Vos proches pourront vous offrir des articles ou vous envoyer de l’argent pour que vous les achetiez vous-même. En tant que propriétaire de la liste, vous ne payez rien pour recevoir de l'argent.
          </p>
        </template>
      </LandingFeaturesCoverItem>
      <LandingFeaturesCoverItem>
        <template slot="cover">
          <img src="../../assets/img/covers/mockup-aportacions.png" alt="" />
        </template>
        <template slot="titulo"> Offrez la possibilité de se cotiser pour les achats les plus chers </template>

        <template slot="descripcion">
          <p>
              Votre famille et vos amis pourront faire des contributions partielles pour acheter à plusieurs les produits les plus chers.
          </p>
        </template>
      </LandingFeaturesCoverItem>
    </LandingFeaturesCover>

    <main class="bg-cercles">
      <section class="bb-container">
        <LandingReviewsWedding lang="fr-FR" />
      </section>
    </main>

    <LandingComoCols title="Comment ça marche ?" subtitle="Votre liste de naissance en ligne.">
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
        </template>
        <template slot="titulo">Choisissez ce que vous voulez</template>
        <template slot="descripcion">
          <p>
            Sélectionnez les produits de votre choix dans n’importe quelle boutique en ligne, copiez le lien sur HelloBB et sauvegardez-le.
          </p>
          <p>
              Vous pouvez aussi créer une Cagnotte pour collecter de l’argent et acheter ce dont vous avez besoin.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
        </template>
        <template slot="titulo">Partagez vos envies</template>
        <template slot="descripcion">
          <p>Quand vous êtes prêt, partagez votre liste avec vos amis et votre famille.</p>
          <p>
             Envoyez-leur un lien pour qu’ils accèdent à une belle liste personnalisée, depuis n’importe quel appareil et sans devoir s’inscrire.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
        </template>
        <template slot="titulo">Recevez vos cadeaux</template>
        <template slot="descripcion">
          <p>
            Vos proches pourront choisir le cadeau de naissance qu’ils préfèrent dans votre liste et l’acheter. Ils pourront vous l’envoyer directement ou le garder pour vous l’offrir lors d’une visite.
          </p>
          <p>
            Pour éviter les doublons, ils pourront indiquer que l’article a déjà été offert. 
          </p>
        </template>
      </LandingComoColsItem>
    </LandingComoCols>

    <LandingFooter lang="fr-FR" />
  </div>
</template>

<script>
import LandingFeaturesSlider from "./components/LandingFeaturesSlider.vue";

import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";

import LandingHero from "./components/LandingHero.vue";
import LandingAppRatings from "./components/LandingAppRatings.vue";
import LandingFeaturesCover from "./components/LandingFeaturesCover";
import LandingFeaturesCoverItem from "./components/LandingFeaturesCoverItem";
import LandingReviews from "./components/LandingReviews.vue";
import LandingHablan from "./components/LandingHablan.vue";
import LandingReviewsWedding from "./components/LandingReviewsWedding.vue";
import LandingFooter from "./components/LandingFooter.vue";

import LandingComoCards from "./components/LandingComoCards.vue";
import LandingComoCardsItem from "./components/LandingComoCardsItem.vue";
import LandingComoColsItem from "./components/LandingComoColsItem.vue";
import LandingComoCols from "./components/LandingComoCols.vue";

// import bbButton from "../../components/buttons/bbButton.vue";

export default {
  name: "LandingNoListFR",
  metaInfo() {
    return {
      title: "HelloBB : la meilleure liste de naissance pour votre famille",
      meta: [
        { name: "description", content: "HelloBB est la liste de naissance où tous vos souhaits ont leur place." },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    LandingComoCols,
    LandingComoColsItem,
    LandingAppRatings,
    LandingHablan,
    LandingReviews,
    LandingFooter,
    LandingHero,
    LandingFeaturesCover,
    LandingFeaturesCoverItem,
    LandingFeaturesSlider,
    LandingFeaturesSimple,
    LandingFeaturesSimpleItem,
    LandingComoCards,
    LandingComoCardsItem,
    LandingReviewsWedding,
  },
};
</script>

<style lang="scss" scoped></style>